import React from "react";
// import "./styles.css";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
// import { ReactComponent as Logo } from "./logo.svg";
import "./MobileNavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

export default function MobileNavbar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    if (url === "taggedWithMe") {
      navigate("/");
    } else if (url === "task") {
      navigate("/task");
    }
  };

  return (
    <Navbar className="smallScreen shadow-sm" collapseOnSelect expand="lg">
      <Navbar.Brand href="#home" className="text-light">
        <h5>
          {location.pathname === "/task"
            ? "My Reports"
            : location.pathname === "/" && "Students tagged with me"}
        </h5>
        {/* <Logo
          alt=""
          width="30"
          height="30"
          className="d-inline-block align-top"
        /> */}
        {/* {`${headerData ? headerData[0]?.jamaat : ""}`} Age Group: 3-27 */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <FontAwesomeIcon color={"#fff"} icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link
              className="text-light text-start"
              onClick={() => handleNavigate("home")}
            >
              Home
            </Nav.Link> */}
            <Nav.Link
              className="text-light text-start"
              onClick={() => handleNavigate("taggedWithMe")}
            >
              Students tagged with me
            </Nav.Link>
            <Nav.Link
              className="text-light text-start"
              onClick={() => handleNavigate("task")}
            >
              My Reports
            </Nav.Link>
            {/* <Nav.Link className="text-light text-start"  onClick={ () => handleNavigate("task")} >My Tasks</Nav.Link> */}
          </Nav>
        </Navbar.Toggle>
      </Navbar.Collapse>
    </Navbar>
  );
}
