import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./Routes/AppRoutes";
import MigrateStudent from "./components/Migrated/MigrateStudent";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import TaskRedirect from "./components/MyTask/TaskRedirect";

import MyTask from "./components/MyTask/MyTask";
import MainNavbar from "./components/MainNavbar";
import MobileNavbar from "./components/MobileNavbar";
import useFetch from "./useFetch";
import { useEffect, useState, createContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TaskIDProvider, { useTaskId } from "./Contexts/TaskIDContext";

export const DataContext = createContext({
  currentSettings: "",
});
function AppContent() {
  const { taskId, setTaskId } = useTaskId();
  const [currentJamaatName, setCurrentJamaatName] = useState("");

  const changeCurrentJamaatName = (values) => {
    setCurrentJamaatName(values);
  };
  const [userFullName, fetchuserFullName] = useFetch();
  const [utDetails, fetchUtMemberDetails] = useFetch();

  useEffect(() => {
    fetchuserFullName(`${process.env.REACT_APP_PROFILE_API}`);
    fetchUtMemberDetails(`${process.env.REACT_APP_PROFILE_API}ut/details`);
  }, [fetchuserFullName, fetchUtMemberDetails]);

  const isAuthorized = utDetails?.role !== "";
  if (!isAuthorized) {
    return (
      <div>
        <h1> You are not authorized to view this page</h1>
        <a href="https://talabulilm.com">Go back to talabulilm.com</a>
      </div>
    );
  }

  const isSupervisor = utDetails?.role === "amil_saheb_coordinator";
  const isSupervisorOrUTMember =
    utDetails?.role === "amil_saheb_coordinator" ||
    utDetails?.role === process.env.REACT_APP_GUARDIAN_ROLE;

  if (!isSupervisorOrUTMember) {
    return (
      <div>
        <h1> Please wait while we authenticate your login..</h1>

        <a href="https://talabulilm.com">Go back to talabulilm.com</a>
      </div>
    );
  }

  return (
    <DataContext.Provider
      value={{ currentJamaatName, changeCurrentJamaatName }}
    >
      {utDetails?.length === 0 ? (
        <div>
          <h1> You are not authorised to view this page</h1>
          <a href="https://talabulilm.com">Go back to talabulilm.com</a>
        </div>
      ) : (
        <BrowserRouter>
          <MainNavbar userFullName={userFullName} isSupervisor={isSupervisor} />
          <MobileNavbar />
          <Routes>
            <Route
              path="/"
              element={<AppRoutes utDetails={utDetails ? utDetails : "NA"} />}
            />
            <Route path="/task" element={<MyTask />} />
            <Route
              path={`/task/:taskId`}
              element={
                <TaskRedirect utDetails={utDetails ? utDetails : "NA"} />
              }
            />
            {isSupervisor && (
              <Route path="/migrated-student" element={<MigrateStudent />} />
            )}
          </Routes>
        </BrowserRouter>
      )}
      <ToastContainer />
    </DataContext.Provider>
  );
}

export default function App() {
  return (
    <TaskIDProvider>
      <AppContent />
    </TaskIDProvider>
  );
}
