import { NavLink } from "react-router-dom";
import logo from "../logotal.png";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { getCookie } from "../Utils/cookieUtils";
import "./MainNavbar.css";

export default function MainNavbar(props) {
  const { userFullName, isSupervisor } = props;
  const userName = getCookie("user_its");

  return (
    <Navbar className="largeContainer" style={{ backgroundColor: "#002147" }}>
      <Container fluid>
        <div
          className="d-flex justify-content-start nav-container"
          style={{ maxWidth: "40%" }}
        >
          <a href="https://www.talabulilm.com">
            <img className="" src={logo} alt="img" />
          </a>
          <div style={{ marginLeft: "30px" }}>
            <NavLink
              to={`/`}
              className={({ isActive }) =>
                isActive ? "top-nav-link active" : "top-nav-link"
              }
            >
              Students Tagged with Me
            </NavLink>
            <NavLink
              to={`/task`}
              className={({ isActive }) =>
                isActive ? "top-nav-link active" : "top-nav-link"
              }
            >
              My Reports
            </NavLink>

            {isSupervisor && ( // Conditionally render "Migrated-Student" tab for supervisors
              <NavLink
                to={`/migrated-student`}
                className={({ isActive }) =>
                  isActive ? "top-nav-link active" : "top-nav-link"
                }
              >
                Guardian List
              </NavLink>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end nav-container">
          <div className="d-flex">
            <div className="image-header">{userFullName?.name}</div>
            <img
              className="image-content"
              src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${userName}.png`}
              alt="img"
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
