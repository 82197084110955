import React, { createContext, useContext, useState } from "react";

const TaskIDContext = createContext();

const TaskIDProvider = ({ children }) => {
  const [taskId, setTaskId] = useState(null);
  const [isTaskActive, setIsTaskActive] = useState();

  return (
    <TaskIDContext.Provider
      value={{ taskId, setTaskId, isTaskActive, setIsTaskActive }}
    >
      {children}
    </TaskIDContext.Provider>
  );
};

export default TaskIDProvider;

export const useTaskId = () => useContext(TaskIDContext);
