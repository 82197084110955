import React from "react";

const TaskReport = ({ attendence, remarks }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h6>Has Attended:</h6>
      <p>{attendence === 0 ? "No" : "Yes"}</p>
      <h6>Remarks:</h6>
      <p>{remarks}</p>
    </div>
  );
};

export default TaskReport;
