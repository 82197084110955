import React, { useEffect, useState, useContext } from "react";
import LandingPage from "../components/LangingPage";
import useFetch from "../useFetch";
import { ToastContainer } from "react-toastify";
import { DataContext } from "../App";
import { getTokenFromCookie } from "../Utils/cookieUtils";
import MyTask from "../components/MyTask/MyTask";

export default function AppRoutes(props) {
  const userRole = props.utDetails.role;

  const verb =
    userRole === process.env.REACT_APP_GUARDIAN_ROLE
      ? `muraqeebTagList`
      : `migratedInUserList`;

  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [utMemeber, setUtMemeber] = useFetch();
  const [ageLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);
  const [downloadRecord] = useState(
    `?cmd=razastatus&jamaat=${currentJamaatId}&raza_status=Araz%20done`
  );

  const handleRequest = () => {
    setApiData("");
    setSort(defaultSort);
    setApiData(`${process.env.REACT_APP_PROFILE_API}ut/${verb}`);
    setCurrentData(apiData);
  };

  const handleDataRefresh = () => {
    setApiData(`${process.env.REACT_APP_PROFILE_API}ut/${verb}`);
  };

  useEffect(() => {
    if (!ageLimit) return;
    if (!currentJamaatId) return;
  }, [ageLimit]);

  useEffect(() => {
    const tokens = getTokenFromCookie();
    setToken(tokens);
    if (allJamaatIds && allJamaatIds.length > 0) {
      const inititalData = allJamaatIds?.filter(
        (item) => item.is_primary === 1
      );
      setUtMemeber(
        `${process.env.REACT_APP_PROFILE_API}ut/getUTMemberDetailsByJamaatID/${inititalData[0]?.jamaat_id}`
      );
      setCurrentJamaatId(inititalData[0]?.jamaat_id);
    }
  }, [allJamaatIds]);

  useEffect(() => {
    setAllJamaatIds(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/jamaatIDByitsID`
    );
  }, [token]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  const handleSort = (field) => {
    let direction = sort.direction;
    if (field === sort.field) {
      direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }
    const sortedData = currentData?.sort((a, b) => {
      if (field === "age")
        return direction === "asc" ? a[field] - b[field] : b[field] - a[field];
      return direction === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    });
    setCurrentData(sortedData);
    setSort({ field: field, direction: direction });
  };

  const handleJamaatChange = (jamaatId, jamaat) => {
    setCurrentJamaatId(jamaatId);
  };

  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <>
      <LandingPage
        currentData={currentData}
        utMemeber={utMemeber}
        sort={sort}
        handleJamaatChange={handleJamaatChange}
        allJamaatIds={allJamaatIds}
        downloadRecord={downloadRecord}
        handleRequest={handleRequest}
        handleSort={handleSort}
        jamaatId={currentJamaatId}
        handleDataRefresh={handleDataRefresh}
        userRole={userRole}
      />
      <ToastContainer />
    </>
  );
}
