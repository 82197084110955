import { Buffer } from "buffer";

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const getTokenFromCookie = () => {
  if (getCookie("user_its") === null) {
    window.location.replace(process.env.React_APP_LOGIN_LINK);
    return;
  }

  const username = getCookie("user_its");
  const password = getCookie("ver");

  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );

  return token;
};
