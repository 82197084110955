import React, { useEffect } from "react";
import useFetch from "../../useFetch";
import HashLoader from "react-spinners/HashLoader";
import AttendenceSubmission from "./AttendenceSubmission";
import { useTaskId } from "../../Contexts/TaskIDContext";
import TaskReport from "./TaskReport";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

function TaskRedirect(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [currentData, setCurrentData] = useFetch();
  const { taskId, setTaskId, isTaskActive, setIsTaskActive } = useTaskId();

  useEffect(() => {
    setCurrentData(
      `${process.env.REACT_APP_PROFILE_API}ut/muraqeebTagListByTaskID/${params.taskId}`
    );
  }, []);

  return (
    <div>
      <Button
        variant="secondary"
        style={{ margin: "0.8em 0.4em" }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      {currentData?.length > 0 ? (
        <>
          {currentData?.map((student, idx) => (
            <div key={`student-${student.its_id}`} className="user-card">
              <div className="user-card-content">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", height: "100%", width: "42%" }}
                >
                  <img
                    src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${student.its_id}.png`}
                    alt="user"
                    className="user-img"
                  />
                  <div className="user-details d-flex flex-column">
                    <p>{student.its_id}</p>
                    <p>{student.name}</p>
                    <p>
                      {student.gender.toLowerCase() === "m" ? "Male" : "Female"}{" "}
                      {student.age} years
                    </p>
                    <a
                      href={`mailto:${student.email}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      {student.email}
                    </a>
                    <a
                      href={`tel:${student.mobile}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      {student.mobile}
                    </a>
                    <p>
                      <strong>Jamaat:</strong> {student.jamaat}
                    </p>
                    <p>
                      <strong>Current City:</strong> {student.current_edu_city}
                    </p>
                  </div>
                </div>

                <div
                  className="study-history"
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "1em",
                  }}
                >
                  {isTaskActive ? (
                    <AttendenceSubmission student={student} />
                  ) : (
                    <TaskReport
                      attendence={student.attendence}
                      remarks={student.remarks}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : currentData && currentData.length === 0 ? (
        <div className="loader-content">No data found ....</div>
      ) : (
        <div className="loader-content">
          <HashLoader color={"#00336D"} size={78} />
        </div>
      )}
    </div>
  );
}

export default TaskRedirect;
