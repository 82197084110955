import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Stack,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";

import useFetch from "../../useFetch";

import { getTokenFromCookie } from "../../Utils/cookieUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import MigratedInStudent from "./MigratedInStudent";

export default function MigratedStudent(props) {
  const [toggle, setToggle] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userData, setUserData] = useFetch("");

  const [imgUrl, setImgUrl] = useState("");

  const [jamaatUrl, setJamaatUrl] = useFetch("");
  const [jamaatId, setJamaatId] = useState("");
  const [list, setList] = useFetch({});

  const [guardianTaggedList, setGuardianTaggedList] = useState(null);
  const [guardianITS, setGuardianITS] = useState();
  const [showGuardianList, setShowGuardianList] = useState(false);
  const [guardianListLoading, setGuardianListLoading] = useState(false);

  const getToken = getTokenFromCookie();

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClick = (e) => {
    if (inputValue.trim() === "") {
      toast.error("ITS_ID Required!");
      return;
    }
    setUserData(
      `${process.env.REACT_APP_BASE_API}migrating_students/user/details/${inputValue}`
    );
    setImgUrl(`${process.env.REACT_APP_MUMIN_IMAGE_URL}${inputValue}.png`);

    if (!toggle) {
      setToggle(true);
    }
  };

  useEffect(() => {
    setJamaatUrl(`${process.env.REACT_APP_PROFILE_API}ut/details`);
  }, []);

  const fetchGuardianList = async () => {
    if (jamaatUrl) {
      setJamaatId(jamaatUrl.jamaat_id);
      if (jamaatId) {
        const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/userList/${jamaatId}`;
        setList(userListUrl);
      }
    }
  };

  useEffect(() => {
    fetchGuardianList();
  }, [jamaatUrl, jamaatId]);

  // Add New User Fetch Api
  const handlePostRequest = () => {
    const postData = {
      its_id: `${inputValue}`,
      role: "",
      jamaat_id: `${jamaatId}`,
      is_active: "",
    };

    const apiUrl = `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/addUser`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
      body: JSON.stringify(postData),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Boolean(data.status)) {
          toast.success("New User Added", data);
          setList(userListUrl);
        } else {
          toast.error(data.error);
        }
        const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/userList/${jamaatId}`;
      })
      .catch((error) => {
        toast.error("There was a problem with the POST request:", error);
      });
  };
  // CheckBox Api Fetch
  const handlePostCheckRequest = async (itsId) => {
    try {
      const postData = {
        its_id: itsId,
        role: "",
        jamaat_id: `${jamaatId}`,
        is_active: "",
      };
      const apiUrl = `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/assignSupervisor`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getToken}`,
        },
        body: JSON.stringify(postData),
      };

      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      toast.success("Made Supervisor", data);

      const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/aamilsaheb/userList/${jamaatId}`;
      setList(userListUrl);
    } catch (error) {
      toast.error("There was a problem with the POST request:", error);
    }
  };

  useEffect(() => {
    showGuardianList && getGuardianList(guardianITS);
  }, [showGuardianList]);

  const getGuardianList = async (itsId) => {
    setGuardianListLoading(true);
    await fetch(
      `${process.env.REACT_APP_BASE_API}profile/ut/getTagStudentListByGardianITS/${itsId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getToken}`,
        },
      }
    )
      .then((res) => res.status === 200 && res.json())
      .then(
        (data) => (setGuardianTaggedList(data), setGuardianListLoading(false))
      );
  };

  return (
    <>
      <Row
        style={{
          backgroundColor: "#E5E5E5",
          margin: "0px",
        }}
        className="main-content"
      >
        {/* Left Section
        <Col xs={12} lg={6} className="mt-3">
          <div className="explaination-box d-none d-sm-block">
            <Form.Text className="text-info">
              <h5>Add Guardians</h5>
            </Form.Text>
            <hr className="underline" />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <InputGroup className="w-50 margin">
                <Form.Control
                  onChange={handleChange}
                  type="number"
                  placeholder="Enter ITS_ID"
                  aria-label="id"
                  aria-describedby="basic-addon1"
                />
                <Button variant="info" id="search-btn" onClick={handleClick}>
                  Fetch
                </Button>
              </InputGroup>
            </Form.Group>
            {toggle && userData ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imgUrl}
                    alt="user-avatar"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                  <ul>
                    <li
                      style={{
                        fontWeight: "bold",
                        listStyle: "none",
                      }}
                    >
                      {userData?.full_name_en}
                    </li>
                    <li
                      style={{
                        fontWeight: "lighter",
                        listStyle: "none",
                      }}
                    >
                      {userData?.email}
                    </li>
                  </ul>
                </div>
                <Stack
                  direction="horizontal"
                  gap={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    as="a"
                    variant="success"
                    onClick={handlePostRequest}
                    style={{ margin: "10px" }}
                  >
                    Add user
                  </Button>
                </Stack>
              </Form.Group>
            ) : (
              ""
            )}
          </div>
        </Col> */}

        <MigratedInStudent
          dropdownList={list}
          fetchGuardianList={fetchGuardianList}
        />

        {/* Right Section */}
        <Col xs={12} lg={6} className="mt-3">
          <div className="explaination-box d-none d-sm-block">
            <Form.Text className="text-info">
              <h5>Add Guardians</h5>
            </Form.Text>
            <hr className="underline" />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <InputGroup className="w-50 margin">
                <Form.Control
                  onChange={handleChange}
                  type="number"
                  placeholder="Enter ITS_ID"
                  aria-label="id"
                  aria-describedby="basic-addon1"
                />
                <Button variant="info" id="search-btn" onClick={handleClick}>
                  Fetch
                </Button>
              </InputGroup>
            </Form.Group>
            {toggle && userData ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imgUrl}
                    alt="user-avatar"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                  <ul>
                    <li
                      style={{
                        fontWeight: "bold",
                        listStyle: "none",
                      }}
                    >
                      {userData?.full_name_en}
                    </li>
                    <li
                      style={{
                        fontWeight: "lighter",
                        listStyle: "none",
                      }}
                    >
                      {userData?.email}
                    </li>
                  </ul>
                </div>
                <Stack
                  direction="horizontal"
                  gap={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    as="a"
                    variant="success"
                    onClick={handlePostRequest}
                    style={{ margin: "10px" }}
                  >
                    Add user
                  </Button>
                </Stack>
              </Form.Group>
            ) : (
              ""
            )}
          </div>

          <div className="explaination-box d-none d-sm-block">
            <Form className="p-0">
              <>
                <div
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Text className="text-info">
                    <h5>Guardians</h5>
                    <hr className="underline" />
                  </Form.Text>

                  <div className="d-flex flex-column gap-4">
                    {!list ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </>
                    ) : (
                      list.map((item, index) => (
                        <div key={index} className="d-flex">
                          <div
                            style={{
                              flex: 1,
                              padding: "1rem 1.4rem",
                              boxShadow: "0 0.2rem 0.2rem #dfdfdf",
                              borderRadius: "0.6rem",
                            }}
                          >
                            <div
                              onClick={() => (
                                setShowGuardianList((prev) => !prev),
                                setGuardianITS(item.its_id)
                              )}
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flex: 1,
                                cursor: "pointer",
                              }}
                            >
                              <div className="d-flex">
                                <img
                                  src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${item.its_id}.png`}
                                  alt="user-avatar"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <ul className="m-0">
                                  <li
                                    style={{
                                      fontWeight: "light",
                                      listStyle: "none",
                                    }}
                                  >
                                    {item.name}{" "}
                                  </li>
                                  <li
                                    style={{
                                      fontWeight: "lighter",
                                      listStyle: "none",
                                    }}
                                  >
                                    {`${item.its_id} - ${item.role
                                      .split("_")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(" ")}`}
                                  </li>
                                  {/* <div
                                    class="form-check form-switch"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                      onChange={() =>
                                        handlePostCheckRequest(item.its_id)
                                      }
                                      checked={item.role !== "guardian"}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <label
                                      class="form-check-label"
                                      style={{
                                        fontWeight: 400,
                                      }}
                                      for="flexSwitchCheckDefault"
                                    >
                                      Make Aamil Saheb Coordinator
                                    </label>
                                  </div> */}
                                </ul>
                              </div>
                              <div>
                                <p className="text-center">
                                  {item.taggingCount}
                                </p>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </div>
                            </div>
                            {guardianListLoading &&
                            item.its_id === guardianITS ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Loading...
                              </>
                            ) : (
                              <>
                                {showGuardianList &&
                                  guardianTaggedList &&
                                  item.its_id === guardianITS && (
                                    <div
                                      style={{
                                        borderRadius: "0.4rem",
                                        padding: "0.2rem 1.2rem",
                                      }}
                                    >
                                      {guardianTaggedList?.map((student, i) => (
                                        <div
                                          className="my-4 d-flex align-items-center gap-4"
                                          key={student.its_id + i}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${student.its_id}.png`}
                                            alt="user-avatar"
                                            style={{
                                              width: "70px",
                                              height: "70px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                          <div
                                            className="d-flex"
                                            style={{ flex: 1, gap: "0.8rem" }}
                                          >
                                            <div style={{ flex: 1 }}>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>ITS ID: </b>
                                                {student.its_id}
                                              </p>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>Name: </b>
                                                {student.name}
                                              </p>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>Jamaat: </b>
                                                {student.jamaat}
                                              </p>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>Age: </b>
                                                {student.age}
                                              </p>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>Email: </b>
                                                {student.email}
                                              </p>
                                              <p
                                                className="m-0"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                <b>Mobile: </b>
                                                {student.mobile}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </>
            </Form>
          </div>
        </Col>
      </Row>
      <ToastContainer autoClose={1000} hideProgressBar={true} />
    </>
  );
}
