import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import EducationDetail from "./EducationDetail";

import HashLoader from "react-spinners/HashLoader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function LandingPage(props) {
  const {
    sort,
    currentData,
    utMemeber,
    handleSort,
    userRole,
    handleDataRefresh,
  } = props;
  const [color, setColor] = useState("#00336D");

  return (
    <Row
      style={{
        backgroundColor: "#E5E5E5",
        margin: "0px",
      }}
    >
      <Col xs={12} lg={12} className="main-content mt-1">
        <div>
          <span className="label">Sort By: </span>
          <button
            type="button"
            className={`btn  ${
              sort.field === "age" ? "btn-sort-active" : "btn-sort"
            }`}
            onClick={() => handleSort("age")}
          >
            Age{" "}
            {sort.direction === "asc" && sort.field === "age" ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : sort.direction === "desc" && sort.field === "age" ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              ""
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              sort.field === "name" ? "btn-sort-active" : "btn-sort"
            }`}
            onClick={() => handleSort("name")}
          >
            Name{" "}
            {sort.direction === "asc" && sort.field === "name" ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : sort.direction === "desc" && sort.field === "name" ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              ""
            )}{" "}
          </button>
          <button
            type="button"
            className={`btn ${
              sort.field === "gender" ? "btn-sort-active" : "btn-sort"
            }`}
            onClick={() => handleSort("gender")}
          >
            Gender{" "}
            {sort.direction === "asc" && sort.field === "gender" ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : sort.direction === "desc" && sort.field === "gender" ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              ""
            )}{" "}
          </button>
          <button
            type="button"
            className={`btn ${
              sort.field === "current_edu_course"
                ? "btn-sort-active"
                : "btn-sort"
            }`}
            onClick={() => handleSort("current_edu_course")}
          >
            Current Course{" "}
            {sort.direction === "asc" && sort.field === "current_edu_course" ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : sort.direction === "desc" &&
              sort.field === "current_edu_course" ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              ""
            )}{" "}
          </button>
        </div>
        {currentData && currentData.length !== 0 ? (
          <EducationDetail
            currentData={currentData}
            utMemeber={utMemeber}
            handleDataRefresh={handleDataRefresh}
            userRole={userRole}
          />
        ) : currentData && currentData.length === 0 ? (
          <div className="loader-content">No data found ....</div>
        ) : (
          <div className="loader-content">
            <HashLoader color={color} size={78} />
          </div>
        )}
      </Col>
    </Row>
  );
}
