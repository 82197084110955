import useFetch from "../../useFetch";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { useTaskId } from "../../Contexts/TaskIDContext";

function MyTask(props) {
  const { taskId, setTaskId, isTaskActive, setIsTaskActive } = useTaskId();
  const [myTask] = useFetch(
    `${process.env.REACT_APP_BASE_API}migrating_students/user/taskList`
  );

  return (
    <div className="col-12 px-2 mt-3">
      <ul className="task-list" style={{ listStyleType: "none", padding: 0 }}>
        {myTask?.map((item, idx) => (
          <li
            key={idx}
            className={`task-item ${
              item.sub_id === null ? "pending" : "completed"
            }`}
            style={{
              backgroundColor: "#FCFCFC",
              color: "#000",
              borderRadius: "5px",
              textDecoration: "none",
              padding: "10px",
              marginBottom: "10px",
              border: "2px solid #e8c35a",
            }}
          >
            <Link
              to={{
                pathname: `/task/${item.id}`,
              }}
              className="task-link"
              style={{
                color: "#000",
                textDecoration: "none",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => (
                setTaskId(item.id),
                setIsTaskActive(new Date() < new Date(item.end_date))
              )}
            >
              <div className="d-flex justify-content-between align-items-center w-100 task-title">
                {item.task_title}
                <ProgressBar
                  className="w-50 h-100 fs-6"
                  now={
                    item?.SubmissionCount && item?.TotalCount
                      ? `${Math.floor(
                          (item.SubmissionCount / item.TotalCount) * 100
                        )}`
                      : 0
                  }
                  variant="success"
                  label={
                    item?.SubmissionCount && item?.TotalCount
                      ? `${Math.floor(
                          (item.SubmissionCount / item.TotalCount) * 100
                        )}%`
                      : "0%"
                  }
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MyTask;
