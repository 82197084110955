import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./AttendenceSubmission.css";

import { getTokenFromCookie } from "../../../Utils/cookieUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AttendenceSubmission({ student }) {
  const [attendance, setAttendance] = useState(student.attendance);
  const [remarks, setRemarks] = useState(student.remarks);
  const { its_id } = student;

  const handleAttendanceChange = async (newAttendance) => {
    setAttendance(newAttendance);
    await fetch(
      `${process.env.REACT_APP_BASE_API}migrating_students/user/updateTaskSubmissionReport/${student.its_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getTokenFromCookie()}`,
        },
        body: JSON.stringify({ attendance: newAttendance }),
      }
    )
      .then((response) => {
        !response.ok && toast.error("An error occured");
        return response.json();
      })
      .then((data) => toast.success("Data Has Been Updated"))
      .catch((error) => toast.error("There was a problem posting the data:"));
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const updateRemarksAPI = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_API}migrating_students/user/updateTaskSubmissionRemarks/${its_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getTokenFromCookie()}`,
        },
        body: JSON.stringify({ remarks }),
      }
    )
      .then((response) => {
        !response.ok && toast.error("An error occured");
        return response.json();
      })
      .then((data) => toast.success("Remarks Updated Successfully"))
      .catch((error) => toast.error("There was a problem posting remarks"));
  };

  return (
    <div
      className="attendence-submission"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <div className="att-con">
        <Form.Label className="me-2">Has Attended:</Form.Label>
        <Form.Check
          type="radio"
          inline
          label="Yes"
          id={`${its_id}yes`}
          name={`attendance` + its_id}
          value="1"
          checked={attendance === "1"}
          onChange={(e) => handleAttendanceChange(e.target.value)}
          style={radioStyle}
        />
        <Form.Check
          type="radio"
          inline
          label="No"
          id={`${its_id}no`}
          name={`attendance` + its_id}
          value="0"
          checked={attendance === "0"}
          onChange={(e) => handleAttendanceChange(e.target.value)}
          style={radioStyle}
        />
      </div>
      <Form.Label>Remarks:</Form.Label>
      <Form.Control
        as="textarea"
        className="textarea"
        value={remarks}
        onChange={handleRemarksChange}
        onBlur={updateRemarksAPI}
      />
      <Button
        onClick={() => updateRemarksAPI()}
        style={{ marginBottom: "10px" }}
      >
        Submit
      </Button>
    </div>
  );
}

const radioStyle = {
  marginBottom: "5px",
};
